import {
  AdobeAnnotationData,
  SubTaskMessage
} from './../../../../../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/_models/tasks/SubTaskMessage';
import { Component, OnDestroy, OnInit } from '@angular/core';
import
{
  TextDifference,
  DiffType,
  TranslationService,
  MoonDeskDocument
} from '../../../../../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import
{
  HighlightRect,
  PdfFile,
  PdfViewerMode,
  ScrollPosition,
  ToolbarButton,
} from '../../moon-pdf-viewer/moon-pdf-viewer.component';
import { MoonPdfViewerService } from '../../moon-pdf-viewer/moon-pdf-viewer.service';
import { OptionalPdfFiles } from '../../moon-pdf-viewer/pdf-uploader/pdf-uploader.component';

const EQUAL_DIFF_COLOR = 'rgba(31, 57, 76, 0.3)';
const INSERTED_DIFF_COLOR = 'rgba(39, 255, 0, 0.25)';
const DELETED_DIFF_COLOR = 'rgba(255, 0, 0, 0.54)';
export interface TextCompareMessageToParent
{
  action:
    'FULL_TEXT_READED' |
    'READY' |
    'SCROLL_CHANGE' |
    'ZOOM_CHANGE' |
    'TEXT_SELECTED' |
    'EQUAL_DIFF_CLICKED' |
    'INSERTED_DIFF_CLICKED' |
    'DELETED_DIFF_CLICKED' |
    'SET_COMPARISON_MODE' |
    'HIGHLIGHT_DIFFS_DONE' |
    'MODE_CHANGE' |
    'SYNC_SCROLL' |
    'ADOBE_ANNOTATION_DATA_CREATED' |
    'OPEN_MOON_DOCUMENT_PICKER';
  payload?: string | ScrollPosition | number | number[] | boolean | AdobeAnnotationData | HighlightsAddedEvent | HighlightClickedEvent;
  iframeIndex?: number;
}

export interface HighlightClickedEvent
{
  groupId: number;
  index: number;
}

export interface HighlightsAddedEvent
{
  diffs: TextDifference[];
  groupId: number;
}

export interface TextCompareMessageToChildren
{
  action:
    'SET_INDEX' |
    'SET_PDF_FILE' |
    'SET_SUBTASKMESSAGES' |
    'SET_ENABLEANNOTATIONS' |
    'SET_MOON_FILE' |
    'SET_OPTIONAL_MOON_FILE' |
    'SET_OPTIONAL_PDF_FILES' |
    'HIGHLIGHT_DIFFS' |
    'SEARCH_TEXT' |
    'HIGHLIGHT_DIFFS_IN_SELECTION' |
    'SET_ACTIVE_HIGHLIGHTS_GROUP' |
    'REMOVE_HIGHLIGHTS_GROUP' |
    'SET_SCROLL' |
    'SET_ZOOM' |
    'FOCUS_EQUAL_DIFF' |
    'FOCUS_DIFF' |
    'REMOVE_HIGHLIGHTS' |
    'SET_COMPARISON_MODE' |
    'SYNC_SCROLL' |
    'ANNOTATION_CANCELLED' |
    'ANNOTATION_FOCUSED'|
    'ANNOTATION_SAVED'|
    'SET_COMPARISON_MODE';
  payload:
    string |
    TextDifference[] |
    ScrollPosition |
    number |
    OptionalPdfFiles[] |
    boolean |
    PdfFile |
    SubTaskMessage[] |
    MoonDeskDocument;
}

interface DiffHighlight extends HighlightRect
{
  textDifference: TextDifference;
  groupId: number;
}

@Component({
  selector: 'app-pdf-viewer-iframe',
  templateUrl: './pdf-viewer-iframe.component.html',
  styleUrl: './pdf-viewer-iframe.component.scss',
  standalone: false
})
export class PdfViewerIframeComponent implements OnInit, OnDestroy
{
  private messageListener: (event: MessageEvent) => void;

  pdfViewerModeEnum = PdfViewerMode;

  document: MoonDeskDocument;
  optionalDocument: MoonDeskDocument;
  // pdfName: string;
  scrollPosition: ScrollPosition;
  zoomLevel: number | string;
  zoomFactor: number;
  selectedParagraphIndex: null | number;

  highlightsGroups: Map<number, DiffHighlight[]> = new Map();
  activeGroupIndex: number;
  activeHighlights: DiffHighlight[] = [];

  iframeIndex = -1;

  comparisonMode: 'Document' | 'Selection';
  changingComparisonMode = false;

  comparePdfViewerToolbarButtons: ToolbarButton[];
  optionalPdfFiles: OptionalPdfFiles[] = [];
  allowSrcChange: boolean;

  subTaskMessages: SubTaskMessage [];
  enableAnnotations: boolean = false;
  constructor(
    private moonPdfViewerService: MoonPdfViewerService,
    private translationService: TranslationService)
  {}

  ngOnInit()
  {
    this.messageListener = (event: MessageEvent) =>
    {
      if (event.origin !== window.location.origin)
      {
        return;
      }

      const data = event.data as TextCompareMessageToChildren;

      switch (data.action)
      {
        case 'SET_INDEX':
          this.iframeIndex = +data.payload;
          if (this.iframeIndex === 1)
          {
            this.allowSrcChange = true;
            this.comparePdfViewerToolbarButtons =
            [
              {
                icon: 'text_select_start',
                label: this.translationService.getTranslation('lid.web2._shared.text-compare.selection'),
                action: this.setComparisonMode.bind(this, 'Selection'),
                isActive: () => this.comparisonMode === 'Selection' && !this.changingComparisonMode,
                isBusy: () => this.comparisonMode === 'Selection' && this.changingComparisonMode
              },
              {
                icon: 'compare_arrows',
                label: this.translationService.getTranslation('lid.web2._shared.text-compare.automatic'),
                action: this.setComparisonMode.bind(this, 'Document'),
                isActive: () => this.comparisonMode === 'Document' && !this.changingComparisonMode,
                isBusy: () => this.comparisonMode === 'Document' && this.changingComparisonMode
              }
            ];
          }
          break;
        case 'SET_MOON_FILE':
          const doc = data.payload as MoonDeskDocument;
          this.document = doc;
          break;
        case 'SET_OPTIONAL_MOON_FILE':
          const optionalDoc = data.payload as MoonDeskDocument;
          this.optionalDocument = optionalDoc;
          break;
        case 'SET_SUBTASKMESSAGES':
          this.subTaskMessages = data.payload as SubTaskMessage [];
          break;
        case 'SET_ENABLEANNOTATIONS':
          this.enableAnnotations = data.payload as boolean;
          break;
        case 'SET_OPTIONAL_PDF_FILES':
          this.optionalPdfFiles = data.payload as OptionalPdfFiles[];
          break;
        case 'HIGHLIGHT_DIFFS':
          this.highlightDifferences(data.payload as TextDifference[], false);
          break;
        case 'SEARCH_TEXT':
          const text = data.payload as string;
          this.searchText(text);
          break;
        case 'HIGHLIGHT_DIFFS_IN_SELECTION':
          this.highlightDifferences(data.payload as TextDifference[], true);
          break;
        case 'SET_ACTIVE_HIGHLIGHTS_GROUP':
          const groupIndex = data.payload as number;
          this.setActiveHighlights(groupIndex);
          break;
        case 'REMOVE_HIGHLIGHTS_GROUP':
          const groupToRemove = data.payload as number;
          this.highlightsGroups.delete(groupToRemove);

          const activeGroupNumber = this.activeGroupIndex === groupToRemove || this.highlightsGroups.size === 0 ?
            null :
            this.activeGroupIndex;

          this.setActiveHighlights(activeGroupNumber);
          break;
        case 'SET_SCROLL':
          this.scrollPosition = data.payload as ScrollPosition;
          break;
        case 'SET_ZOOM':
          this.zoomLevel = data.payload as number | string;
          break;
        case 'FOCUS_EQUAL_DIFF':
          this.focusEqualDiff(data.payload as number);
          break;
        case 'FOCUS_DIFF':
          this.focusDiff(data.payload as number);
          break;
        case 'SET_COMPARISON_MODE':
          this.onComparisonModeChange(data.payload as 'Document' | 'Selection');
          break;
        case 'ANNOTATION_CANCELLED':
          this.onAnnotationCancelled();
          break;
        case 'ANNOTATION_FOCUSED':
          this.onAnnotationFocused(data.payload as string);
          break;
        case 'ANNOTATION_SAVED':
          this.onAnnotationSaved();
          break;
      }
    };

    window.addEventListener('message', this.messageListener);
    this.sendMsgToParent({ action: 'READY' });
  }

  ngOnDestroy()
  {
    if (this.messageListener)
    {
      window.removeEventListener('message', this.messageListener);
    }
  }

  get mouseListenerMode(): 'highlightClicks' | 'highlightClicksAndSelection' | null
  {
    if (!this.comparisonMode)
    {
      return null;
    }
    else
    {
      return this.comparisonMode === 'Document' || this.iframeIndex === 2 ? 'highlightClicks' : 'highlightClicksAndSelection';
    }
  }


  onScrollChange(event: ScrollPosition)
  {
    const msg: TextCompareMessageToParent = { action: 'SCROLL_CHANGE', payload: event, iframeIndex: this.iframeIndex };
    this.sendMsgToParent(msg);
  }

  onZoomLevelChange(zoom: number | string)
  {
    const msg: TextCompareMessageToParent = { action: 'ZOOM_CHANGE', payload: zoom, iframeIndex: this.iframeIndex };
    this.sendMsgToParent(msg);
  }

  onTextSelected(text: string)
  {
    const msg: TextCompareMessageToParent = { action: 'TEXT_SELECTED', payload: text, iframeIndex: this.iframeIndex };
    this.sendMsgToParent(msg);
  }

  async onAllTextReaded(allText: string)
  {
    const msg: TextCompareMessageToParent = { action: 'FULL_TEXT_READED', payload: allText, iframeIndex: this.iframeIndex };
    this.sendMsgToParent(msg);
  }

  onHighlightClicked(diffHighlight: HighlightRect)
  {
    const highlightRect = diffHighlight as DiffHighlight;

    if (highlightRect.textDifference.type === DiffType.Equal)
    {
      const equalDiffs = this.activeHighlights.filter(h => h.textDifference.type === DiffType.Equal);
      const index = equalDiffs.indexOf(highlightRect);
      const event: HighlightClickedEvent = { groupId: highlightRect.groupId, index: index };
      const msg: TextCompareMessageToParent = { action: 'EQUAL_DIFF_CLICKED', payload: event, iframeIndex: this.iframeIndex };
      this.sendMsgToParent(msg);
    }
    else
    {
      const index = this.activeHighlights.indexOf(highlightRect);
      const event: HighlightClickedEvent = { groupId: highlightRect.groupId, index: index };
      const msg: TextCompareMessageToParent =
      {
        action: highlightRect.textDifference.type === DiffType.Inserted ? 'INSERTED_DIFF_CLICKED' : 'DELETED_DIFF_CLICKED',
        payload: event,
        iframeIndex: this.iframeIndex
      };
      this.sendMsgToParent(msg);
    }
  }

  onRotationChange()
  {
    this.setComparisonMode(null);
  }

  onPdfViewerModeChange(mode: PdfViewerMode)
  {
    const msg: TextCompareMessageToParent = { action: 'MODE_CHANGE', payload: mode, iframeIndex: this.iframeIndex };
    this.sendMsgToParent(msg);
  }

  onOpenMoonDocumentPicker()
  {
    const msg: TextCompareMessageToParent = { action: 'OPEN_MOON_DOCUMENT_PICKER', iframeIndex: this.iframeIndex };
    this.sendMsgToParent(msg);
  }

  onPdfChanged()
  {
    this.setActiveHighlights(null);
    this.highlightsGroups.clear();
  }

  private setActiveHighlights(groupIndex: number | null)
  {
    this.activeGroupIndex = groupIndex;
    this.activeHighlights = this.highlightsGroups.get(groupIndex) || [];
    const otherGroupHighlights: DiffHighlight[] = [];
    this.highlightsGroups?.forEach((highlights, key) =>
    {
      if (key !== groupIndex)
      {
        otherGroupHighlights.push(...highlights);
      }
    });

    this.moonPdfViewerService.setHighlights(this.activeHighlights, otherGroupHighlights);
  }

  private async searchText(text: string)
  {
    await this.moonPdfViewerService.searchText(text);
  }

  private setComparisonMode(mode: 'Document' | 'Selection')
  {
    this.changingComparisonMode = true;
    mode = this.comparisonMode === mode ? null : mode;
    this.comparisonMode = mode;
    const msg: TextCompareMessageToParent = { action: 'SET_COMPARISON_MODE', payload: mode, iframeIndex: this.iframeIndex };
    this.sendMsgToParent(msg);
  }

  private async onComparisonModeChange(mode: 'Document' | 'Selection' | null)
  {
    this.changingComparisonMode = false;
    this.comparisonMode = mode;
    await this.moonPdfViewerService.searchText('');
    this.activeHighlights = [];
    this.highlightsGroups.clear();
    this.moonPdfViewerService.setHighlights([], []);
  }

  private focusDiff(index: number)
  {
    if (index >= this.activeHighlights.length)
    {
      return;
    }
    const diff = this.activeHighlights[index];
    this.moonPdfViewerService.focusHighlight(diff);
  }

  private focusEqualDiff(index: number)
  {
    const equalDiffs = this.activeHighlights.filter(h => h.textDifference.type === DiffType.Equal);
    if (index >= equalDiffs.length)
    {
      return;
    }
    const equalDiff = equalDiffs[index];
    this.moonPdfViewerService.focusHighlight(equalDiff);
  }

  private async highlightDifferences(diffs: TextDifference[], highlightInSelection: boolean)
  {
    if (!highlightInSelection)
    {
      await this.moonPdfViewerService.searchText('');
    }

    const newGroupIndex = this.highlightsGroups.size > 0 ? Math.max(...Array.from(this.highlightsGroups.keys())) + 1 : 1;
    const newHighlights = await this.mapDiffsToHighlightsRects(diffs, highlightInSelection, newGroupIndex);

    if (highlightInSelection)
    {
      await this.moonPdfViewerService.searchText('');
    }

    this.highlightsGroups.set(newGroupIndex, newHighlights);

    const updatedDiffs = newHighlights.map(h => h.textDifference);
    const highlightsAdded: HighlightsAddedEvent = { diffs: updatedDiffs, groupId: newGroupIndex };
    const msg: TextCompareMessageToParent = { action: 'HIGHLIGHT_DIFFS_DONE', payload: highlightsAdded, iframeIndex: this.iframeIndex };
    this.sendMsgToParent(msg);
  }

  private sendMsgToParent(msg: TextCompareMessageToParent)
  {
    window.parent.postMessage(msg, '*');
  }

  private async mapDiffsToHighlightsRects(
    diffs: TextDifference[],
    highlightInSelection: boolean,
    groupIndex: number): Promise<DiffHighlight[]>
  {
    if (!diffs || diffs.length === 0)
    {
      return [];
    }

    await this.moonPdfViewerService.renderAllPages();

    const spansToAnalyze: HTMLElement[] = this.moonPdfViewerService.getTextSpans(highlightInSelection);

    let generalIndex = 0;
    const highlightData: DiffHighlight[] = [];

    spansToAnalyze.forEach((el: HTMLElement) =>
    {
      const text = el.textContent.normalize('NFKC');

      const pageDiv = el.closest('.page');
      const pageNumber = pageDiv ? parseInt(pageDiv.getAttribute('data-page-number'), 10) : 1;
      const canvasWrapper = pageDiv.querySelector('.canvasWrapper') as HTMLDivElement;
      const wrapperRect = canvasWrapper.getBoundingClientRect();

      for (let index = 0; index < text.length; index++)
      {
        try
        {
          const target = text[index];

          if (target === ' ')
          {
            continue;
          }

          const currentDifference = diffs[generalIndex];

          if (!currentDifference)
          {
            break;
          }

          let color: string;
          switch (currentDifference.type)
          {
            case DiffType.Inserted:
              color = INSERTED_DIFF_COLOR;
              break;
            case DiffType.Deleted:
              color = DELETED_DIFF_COLOR;
              break;
            case DiffType.Equal:
              color = EQUAL_DIFF_COLOR;
              break;
          }

          let charIndex = index;
          let node = el.firstChild;

          // Sometimes a span can contain another span inside it,
          // this could happen when there is a text selection by the search text functionality.
          // In those cases, we need to find the correct span to highlight.
          while (node && charIndex >= 0)
          {
            if (node.nodeType === Node.TEXT_NODE)
              {
                if (charIndex <= node.textContent.length)
                {
                  break;
                }
                charIndex -= node.textContent.length;
              }
              node = node.nextSibling;
          }

          if (node)
          {
            const range = document.createRange();

            if (charIndex === node.textContent.length)
            {
              charIndex--;
            }

            range.setStart(node, charIndex);
            range.setEnd(node, charIndex + 1);


            const rects = range.getClientRects();
            const rect = rects[0];
            if (rect)
            {
              const highlightRect =
              {
                // We add a little margin (1px) to avoid trigger a new comparision when trying to select the diff
                top: rect.top - wrapperRect.top - 1,
                left: rect.left - wrapperRect.left,
                width: rect.width,
                height: rect.height + 1
              };


              const previousHighlight = highlightData[highlightData.length - 1];
              if (previousHighlight &&
                Math.abs(previousHighlight.rect.top - highlightRect.top) <= 3 &&
                previousHighlight.rect.left + previousHighlight.rect.width <= highlightRect.left
              )
              {
                const closestRight = previousHighlight.rect.left + previousHighlight.rect.width;
                highlightRect.width += highlightRect.left - closestRight;
                highlightRect.left = closestRight;
              }

              highlightData.push(
              {
                pageNumber: pageNumber,
                rect: highlightRect,
                color: color,
                zoomFactor: this.zoomFactor,
                textDifference: currentDifference,
                groupId: groupIndex
              });
            }
          }
          generalIndex++;
        }
        catch (error)
        {
          console.error('Error mapping diff', error, diffs[generalIndex]);
          continue;
        }
      }
    });

    return highlightData;
  }


  onAdobeAnnotationDataCreated(adobeAnnotationData: AdobeAnnotationData)
  {
    const msg: TextCompareMessageToParent = { action: 'ADOBE_ANNOTATION_DATA_CREATED', payload: adobeAnnotationData, iframeIndex: 1 };
    this.sendMsgToParent(msg);
  }

  onAnnotationCancelled()
  {
    this.moonPdfViewerService.annotationCanceled();
  }

  onAnnotationFocused(subTaskMessageId: string)
  {
    this.moonPdfViewerService.annotationFocused(subTaskMessageId);
  }

  onAnnotationSaved()
  {
    this.moonPdfViewerService.annotationSaved();
  }
}
