import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AnnotationEditorType,
  BezierPath,
  FindState,
  NgxExtendedPdfViewerService,
  PageRenderedEvent,
  PdfDocumentPropertiesExtractor,
  PdfLayer,
  TextLayerRenderedEvent
} from 'ngx-extended-pdf-viewer';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { MoonPdfViewerService, PdfSerializedAnnotation } from './moon-pdf-viewer.service';
import { AnnotationEditorEvent } from 'ngx-extended-pdf-viewer/lib/events/annotation-editor-layer-event';
import {
  AdobeAnnotationData,
  AuthService,
  SubTaskMessage,
  TranslationService
} from '../../../../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { FeedbackHelperService } from 'src/app/content/services/feedback-helper.service';
import {
  DocumentService,
  ExportFilesIdentifiers,
  MoonDeskDocument
} from '../../../../../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { OptionalPdfFiles } from './pdf-uploader/pdf-uploader.component';
export interface ScrollPosition
{
  scrollTop: number;
  scrollLeft: number;
}
export interface HighlightRect
{
  rect: { left: number; top: number; width: number; height: number };
  pageNumber: number;
  zoomFactor: number;
  color: string;

  div?: HTMLElement;
}

export enum PdfViewerMode
{
  Previewer = 'previewer',
  Annotation = 'annotation',
  Measurer = 'measurer',
  BarcodeReader = 'barcode-reader',
  PixelCompare = 'pixel-compare',
  ImageCompare = 'image-compare',
  TextCompare = 'text-compare'
}

export interface ToolbarButton
{
  icon: string;
  label?: string;
  tooltip?: string;
  isActive: () => boolean;
  isBusy: () => boolean;
  action: () => void;
}

export interface PdfFile
{
  src: string;
  name: string;
}




const FOCUS_HIGHLIGHT_BORDER_COLOR = 'rgb(55, 55, 100)';
const FOCUS_HIGHLIGHT_FILL_COLOR = 'rgba(55, 55, 100, 0.3)';

@Component({
    selector: 'app-moon-pdf-viewer',
    templateUrl: './moon-pdf-viewer.component.html',
    styleUrls: ['./moon-pdf-viewer.component.scss'],
    standalone: false
})
export class MoonPdfViewerComponent implements OnInit, AfterViewInit, OnDestroy
{
  @Input() set src (src: string | MoonDeskDocument)
  {
    if (typeof src === 'string')
    {
      this.pdfSrc = src;
    }
    else if (src && src.latestVersion)
    {
      this.setPdfFromMoonDocument(src);
    }
  }
  @Input() optionalMoonFile: MoonDeskDocument | null = null;
  @Input() pdfName!: string;
  @Input() optionalPdfFiles: OptionalPdfFiles[] = [];
  @Input() allowSrcChange: boolean = false;
  @Input() page: number = 1;
  @Input() showAllPages: boolean;
  @Input() panOnClickDrag: boolean = true;

  @Input() pdfJsViewer: boolean = false;

  emitSelectEvents: boolean = false;
  @Input() set mouseListener(listen: 'highlightClicks' | 'highlightClicksAndSelection' | null)
  {
    this.emitSelectEvents = false;
    if (listen)
    {
      this.emitSelectEvents = listen === 'highlightClicksAndSelection';
      this.setMouseUpListener(true);
    }
    else
    {
      this.setMouseUpListener(false);
    }
  }

  @Input() customBottomToolbarButtons: ToolbarButton[] = [];

  @Input() set zoomLevel(zoom: number | string)
  {
    const zoomNumber: number = isNaN(zoom as number) ? 100 : zoom as number;
    this.setZoomLevel(zoomNumber);
  }
  @Output() zoomLevelChange: EventEmitter<number | string> = new EventEmitter<number | string>();

  @Input() set scrollPosition(scroll: ScrollPosition)
  {
    if (this.viewerContainer)
    {
      this.viewerContainer.scrollTo(scroll.scrollLeft, scroll.scrollTop);
    }
  }
  @Output() scrollChange: EventEmitter<ScrollPosition> = new EventEmitter<ScrollPosition>();

  _viewerMode: PdfViewerMode = PdfViewerMode.Previewer;
  @Input() set viewerMode(mode: PdfViewerMode)
  {
    this._viewerMode = mode;
  }

  @Input() set subTaskMessages (stm: SubTaskMessage [])
  {
    this._subTaskMessages = stm;
    this.subTaskAdobeMessagesToPdfDraw();
  }
  _subTaskMessages: SubTaskMessage [] = [];

  @Input() enableAnnotations: boolean = false;

  @Output() pdfViewerModeChange: EventEmitter<PdfViewerMode> = new EventEmitter<PdfViewerMode>();

  @Output() pdfChanged: EventEmitter<void> = new EventEmitter<void>();
  @Output() pageRendered: EventEmitter<HTMLCanvasElement> = new EventEmitter<HTMLCanvasElement>();
  @Output() totalPages: EventEmitter<number> = new EventEmitter<number>();
  @Output() allTextReaded: EventEmitter<string> = new EventEmitter<string>();
  @Output() textSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() highlightClicked: EventEmitter<HighlightRect> = new EventEmitter<HighlightRect>();
  @Output() zoomFactorChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() rotationChange: EventEmitter<0 | 90 | 180 | 270> = new EventEmitter<0 | 90 | 180 | 270>();
  @Output() openMoonDocumentPicker = new EventEmitter<void>();

  @Output() adobeAnnotationDataCreated = new EventEmitter<AdobeAnnotationData>();
  pdfViewerModeEnum = PdfViewerMode;
  commentModeEnum = AnnotationEditorType;

  onClickAction: 'selectParagraph' | 'emitClickedHighlight' | null = null;
  commentMode: AnnotationEditorType = AnnotationEditorType.NONE;
  pdfSrc: string = '';

  document: MoonDeskDocument;
  selectedVersion: number = 0;
  downloadingFile: boolean;


  pageDivs: Map<number, HTMLElement> = new Map<number, HTMLElement>();
  textLayers: Map<number, HTMLElement> = new Map<number, HTMLElement>();

  highlights: HighlightRect[] = [];
  secondaryHighlights: HighlightRect[] = [];

  focusedHighlightElement: HTMLElement;
  totalPagesCount: number = 0;
  currentPage: number = 0;
  rotation: 0 | 90 | 180 | 270 = 0;
  pdfLoaded: boolean = false;
  layersBusy: boolean = false;
  layers: PdfLayer[] = [];
  showLayersSelector: boolean = false;

  isSuperUser: boolean = false;

  private searchResultSubject = new Subject<boolean>();
  searchResult$ = this.searchResultSubject.asObservable();

  private zoomChangePromise: Promise<void> | null = null;
  private resolveZoomChangePromise: (() => void) | null = null;

  private destroy$ = new Subject<void>();
  private clickSubject = new Subject<MouseEvent>();
  pdfPropertiesExtractor: PdfDocumentPropertiesExtractor = new PdfDocumentPropertiesExtractor();

  // Obsolete
  zoom: number = 1;

  _zoom: number | string = 'auto';
  zoomFactor: number;
  isDragging: boolean;
  busy = true;
  processingAnnotation = false;

  private viewerContainer: HTMLElement | null = null;

  currentAnnotationId: string;
  currentSerializedAnnotation: PdfSerializedAnnotation;
  // An array of saved annotations. That currently are not part of subTaskMessages.
  currentSerializedAnnotations: PdfSerializedAnnotation[] = [];

  constructor(
    private ngxExtendedPdfViewerService: NgxExtendedPdfViewerService,
    private el: ElementRef,
    private translationService: TranslationService,
    private feedbackHelperSerivce: FeedbackHelperService,
    private moonPdfViewerService: MoonPdfViewerService,
    private docService: DocumentService,
    private authService: AuthService)
  {
  }

  ngOnInit(): void
  {
    this.moonPdfViewerService.registerRenderAllPages(this.renderAllPages.bind(this));
    this.moonPdfViewerService.registerGetTextSpans(this.getTextSpans.bind(this));
    this.moonPdfViewerService.registerFocusHighlight(this.focusHighlight.bind(this));
    this.moonPdfViewerService.registerSearchText(this.search.bind(this));
    this.moonPdfViewerService.registerAnnotationCanceled(this.annotationCanceled.bind(this));
    this.moonPdfViewerService.registerAnnotationFocused(this.focusAnnotation.bind(this));
    this.moonPdfViewerService.registerAnnotationSaved(this.annotationSaved.bind(this));
    this.moonPdfViewerService.registerSetHighlights(this.setHighlights.bind(this));

    window.addEventListener('beforeunload', function (event)
    {
      // Remove browser question "Are you sure you want to leave this page?" because of added annotations
      event.stopImmediatePropagation();
    });

    const identity = this.authService.getCurrentIdentity();
    this.isSuperUser = identity?.user?.isSuperUser;
  }

  ngAfterViewInit(): void
  {
    // Listen to click events on the pdf text layer
    this.clickSubject.pipe(debounceTime(100), takeUntil(this.destroy$)).subscribe((event: MouseEvent) =>
    {
      this.highlights.forEach(h =>
      {
        if (h.div)
        {
          h.div.style.pointerEvents = 'auto';
        }
      });

      const selection = window.getSelection();
      const selectedText = selection?.toString().trim();
      if (selectedText && this.emitSelectEvents)
      {
        this.clearParagraphHighlights();

        const range = selection.getRangeAt(0);
        const selectedElements = Array.from(range.commonAncestorContainer.parentElement?.querySelectorAll('span') || []);
        selectedElements.forEach(span =>
        {
          if (selection.containsNode(span, true))
          {
            span.classList.add('highlight');
          }
        });
        selection.removeAllRanges();
        this.textSelected.emit(selectedText);
        return;
      }

      const target = event.target as HTMLElement;
      if (target.classList.contains('textLayer') || target.classList.contains('endOfContent'))
      {
        return;
      }
      if (this.emitSelectEvents)
      {
        const text = this.highlightParagraphAndGetText(target);
        this.textSelected.emit(text);
      }
    });
  }

  ngOnDestroy()
  {
    if (this.viewerContainer)
    {
      this.viewerContainer.removeEventListener('scroll', this.onScroll);
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  async onPdfLoaded()
  {
    try
    {
      this.pdfLoaded = false;
      this.viewerContainer = document.getElementById('viewerContainer');
      if (this.viewerContainer)
      {
        // Just in case
        this.viewerContainer.removeEventListener('scroll', this.onScroll);
        this.viewerContainer.addEventListener('scroll', this.onScroll);
      }
      this.currentPage = 1;
      this.totalPagesCount = 1;
      this.removeAllHighlights();
      this.textLayers.clear();
      this.pageDivs.clear();
      this.showLayersSelector = false;
      this.loadLayers();
      if (this.focusedHighlightElement)
      {
        this.focusedHighlightElement.remove();
      }

      this.totalPagesCount = this.ngxExtendedPdfViewerService.numberOfPages();

      this.pdfLoaded = true;
      this.pdfChanged.emit();

      const fullText = await this.getAllText();

      this.allTextReaded.emit(fullText);
      this.totalPages.emit(this.totalPagesCount);
      await this.subTaskAdobeMessagesToPdfDraw();


      console.log('PDF Loaded');
    }
    catch (err)
    {
      console.error('Error loading PDF', err);
    }
    finally
    {
      this.pdfLoaded = true;
    }
  }

  onZoomChange(event: number | string)
  {
    this.highlights.forEach(h => h.div?.remove());
    this.secondaryHighlights.forEach(h => h.div?.remove());

    this._zoom = event;
    this.zoomLevelChange.emit(event);
  }

  onZoomFactorChange(event: number)
  {
    this.zoomFactor = event;
    if (this.focusedHighlightElement)
    {
      this.focusedHighlightElement.remove();
    }

    if (this.resolveZoomChangePromise)
    {
      this.resolveZoomChangePromise();
      this.zoomChangePromise = null;
      this.resolveZoomChangePromise = null;
    }

    this.zoomFactorChange.emit(this.zoomFactor);
  }

  async onPageRenderedPdfJs(event: PageRenderedEvent)
  {
    const pageNumber = event.pageNumber;
    const pageDiv = event.source.div as HTMLDivElement;
    this.pageDivs.set(pageNumber, pageDiv);


    const highlightPrimaryPromise = this.highlightRects(this.highlights, event.pageNumber, true);
    const highlightSecondaryPromise = this.highlightRects(this.secondaryHighlights, event.pageNumber, false);
    await Promise.all([highlightPrimaryPromise, highlightSecondaryPromise]);
    await this.subTaskAdobeMessagesToPdfDraw();
  }

  onCurrentPageChange(changeEvent: Event)
  {
    const target = changeEvent.target as HTMLInputElement;
    const pageNumber = parseInt(target.value, 10);
    const isValid = !isNaN(pageNumber) && pageNumber > 0 && pageNumber <= this.totalPagesCount;
    if (!isValid)
    {
      target.value = this.currentPage.toString();
      return;
    }
    else
    {
      this.currentPage = pageNumber;
    }
  }

  onFileChange(pdfFile: PdfFile)
  {
    this.pdfLoaded = false;
    this.pdfSrc = pdfFile.src;
    this.pdfName = pdfFile.name;
  }

  onOpenMoonDocumentPicker()
  {
    this.openMoonDocumentPicker.emit();
  }

  goToPage(pageNumber: number)
  {
    if (pageNumber < 1 || pageNumber > this.totalPagesCount)
    {
      return;
    }
    this.currentPage = pageNumber;
  }

  zoomInPdfJs()
  {
    let currentZoom = this._zoom as number;
    if (isNaN(currentZoom))
    {
      currentZoom = 100;
    }
    this.setZoomLevel(currentZoom + 25);
  }

  zoomOutPdfJs()
  {
    let currentZoom = this._zoom as number;
    if (isNaN(currentZoom))
    {
      currentZoom = 100;
    }
    this.setZoomLevel(currentZoom - 25);
  }

  zoomPageFit()
  {
    this.setZoomLevel('page-fit');
  }

  rotate()
  {
    this.rotation = ((this.rotation + 90) % 360) as 0 | 90 | 180 | 270;
    this.rotationChange.emit(this.rotation);
  }

  async onTextLayerRendered(event: TextLayerRenderedEvent)
  {
    this.textLayers.set(event.pageNumber, event.layer.div);
  }

  async toggleLayer(layer: PdfLayer)
  {
    this.layersBusy = true;
    await this.ngxExtendedPdfViewerService.toggleLayer(layer.layerId);
    layer.visible = !layer.visible;
    this.layersBusy = false;
  }

  async ocrPdfFile()
  {
    try
    {
      this.downloadingFile = true;
      const blob = await this.ngxExtendedPdfViewerService.getCurrentDocumentAsBlob();
      this.pdfSrc = null;
      const editablePdf = await this.docService.convertPdfFileToEditable(blob);
      this.pdfSrc = editablePdf;
    }
    catch (error)
    {
      console.error('Error loading OCR PDF', error);
    }
    finally
    {
      this.downloadingFile = false;
    }
  }

  toggleViewerMode(mode: PdfViewerMode)
  {
    // if (mode !== PdfViewerMode.ImageCompare)
    // {
    //   this._viewerMode = mode;
    // }
    this.pdfViewerModeChange.emit(mode);
  }

 async setCommentMode(mode: AnnotationEditorType | number)
  {
    if(mode === this.commentMode)
    {
      await this.ngxExtendedPdfViewerService.switchAnnotationEdtorMode(AnnotationEditorType.NONE);
      this.commentMode = AnnotationEditorType.NONE;
    }
    else
    {
      await this.ngxExtendedPdfViewerService.switchAnnotationEdtorMode(mode);
      this.commentMode = mode;
    }

  }

  async onAnnotationChange(event: AnnotationEditorEvent): Promise<void>
  {
    if(!this.enableAnnotations)
    {
      return;
    }
    if (this.processingAnnotation)
    {
      return;
    }
    if(this.currentSerializedAnnotation)
    {
      // Exist current annotataion.
      return;
    }
    this.processingAnnotation = true;

    try
    {
      if (event.type === 'moved')
      {
        await this.subTaskAdobeMessagesToPdfDraw();

        return;
      }

      if (event.value.isFreeHighlight)
      {
        await this.handleFreeHighlight();
        return;
      }

      let currentComment = event.source.serialize();
      const hexColor = this.moonPdfViewerService.rgbToHex(
        currentComment.color[0],
        currentComment.color[1],
        currentComment.color[2]
      );
      const thickness = currentComment.thickness;

      if (this.currentAnnotationId !== event.source.id)
      {
        await this.updateAnnotationColor(currentComment);
        this.currentAnnotationId = event.source.id;
      }

      currentComment = this.getUpdatedComment(event);
      currentComment.color = this.moonPdfViewerService.hexToRgb(hexColor);
      this.currentSerializedAnnotation = currentComment;

      await this.redrawAnnotation();

      const adobeAnnotationData = this.createAdobeAnnotationData(currentComment, hexColor, thickness, event);
      this.adobeAnnotationDataCreated.emit(adobeAnnotationData);
      this.setCommentMode(AnnotationEditorType.NONE);
    }
    catch(err)
    {
      console.log('Error in onAnnotationChange', err);
    }
    finally
    {
      this.processingAnnotation = false;
    }
  }


  private async handleFreeHighlight(): Promise<void>
  {
    await this.ngxExtendedPdfViewerService.removeEditorAnnotations();
    this.feedbackHelperSerivce.notifyFeedbackAdvanced(
      this.translationService.getTranslation('lid.web2._shared.moon-pdf-viewer.freehighlightDisabled'),
      'warning'
    );
    await this.subTaskAdobeMessagesToPdfDraw();
  }

  private async updateAnnotationColor(currentComment: any): Promise<void>
  {
    await this.ngxExtendedPdfViewerService.removeEditorAnnotations();
    await this.ngxExtendedPdfViewerService.addEditorAnnotation(currentComment);
  }

  private getUpdatedComment(event: AnnotationEditorEvent): any
  {
    //  'Commited' changes refers updated changes on annotation.
    // INK DRAW TYPE : CORRECT VALUE COMES FROM EVENT
    if (this.commentMode === AnnotationEditorType.INK)
    {
      return event.source.serialize();
    }
    // HIGHLIGH AND OTHERS COMES FROM SERIALIZED
    else
    {
      const result = this.ngxExtendedPdfViewerService.getSerializedAnnotations();
      return result[result.length - 1];
    }
  }

  private async redrawAnnotation(): Promise<void>
  {
    this.processingAnnotation = true;
    await this.subTaskAdobeMessagesToPdfDraw();
    await this.ngxExtendedPdfViewerService.addEditorAnnotation(<any>this.currentSerializedAnnotation);
    this.processingAnnotation = false;
  }

  private createAdobeAnnotationData(currentComment: any,
                                    hexColor: string,
                                    thickness: number,
                                    event: AnnotationEditorEvent
                                    ): AdobeAnnotationData
  {
    const adobeAnnotationData: AdobeAnnotationData =
    {
      metadataJson: null,
      adobeAnnotationId: this.moonPdfViewerService.getRandomString(),
    };

    const metadata = this.buildMetadata(currentComment, hexColor, thickness, event);
    adobeAnnotationData.metadataJson = JSON.stringify(metadata);

    return adobeAnnotationData;
  }

  private buildMetadata(currentComment: any, hexColor: string, thickness: number, event: AnnotationEditorEvent): any
  {
    const baseMetadata = {
      source: '6d07d124-ac85-43b3-a867-36930f502ac6',
      selector: {
        node: { index: currentComment.pageIndex },
        strokeColor: hexColor,
        boundingBox: currentComment.rect,
        type: 'AdobeAnnoSelector',
        strokeWidth: thickness,
        opacity: 1,
      },
    };

    if (this.commentMode === AnnotationEditorType.INK)
    {
      return {
          ...baseMetadata,
          selector: {
              ...baseMetadata.selector,
              subtype: 'shape',
              inkList: [currentComment.paths[0].points],
        },
      };
    }
    else
    {
      return {
        ...baseMetadata,
        selector: {
          ...baseMetadata.selector,
          subtype: 'highlight',
          quadPoints: [...currentComment.quadPoints],
        },
      };
    }
  }

  set searchText(text: string)
  {
    this.search(text);
  }

  async setPdfFromMoonDocument(document: MoonDeskDocument, versionNumber: number = 0)
  {
    if (this.document?.id === document.id && this.selectedVersion === versionNumber)
    {
      return;
    }
    try
    {
      this.downloadingFile = true;

      const versionToDownload = versionNumber > 0 ?
        document.documentVersions.find(v => v.versionNumber === versionNumber) :
        document.latestVersion;

      this.selectedVersion = versionToDownload.versionNumber;

      const pdfUrl = await this.docService.getFileUrl(versionToDownload, ExportFilesIdentifiers.PreviewPdf);
      this.pdfSrc = pdfUrl;
      this.pdfName = `${document.documentType?.name} ${document.classValueString}`;
      this.document = document;
    }
    catch (error)
    {
      console.error('Error loading PDF from MoonDesk document', error);
    }
    finally
    {
      this.downloadingFile = false;
    }
  }

  private setZoomLevel(zoom: number | string)
  {
    this._zoom = zoom;
    this.zoomChangePromise = new Promise<void>((resolve) =>
    {
      this.resolveZoomChangePromise = resolve;
    });
  }

  private async loadLayers()
  {
    try
    {
      this.layersBusy = true;
      this.layers = [];
      this.layers = await this.ngxExtendedPdfViewerService.listLayers();
    }
    catch (error)
    {
      // This is a non-critical error, just log it (some documents may not have layers info)
      console.log('Error loading layers', error);
    }
    finally
    {
      this.layersBusy = false;
    }
  }

  /**
   * Emit events based on the clickAction
   * see clickSubject in ngAfterViewInit
   */
  private async setMouseUpListener(listen: boolean)
  {
    if (listen)
    {
      await this.search('');
      this.textLayers.forEach((layer) =>
      {
        layer.addEventListener('mousedown', () => this.highlights.forEach(h =>
        {
          if (h.div)
          {
            h.div.style.pointerEvents = 'none';
          }
        }));
        layer.addEventListener('mouseup', (event: MouseEvent) => { this.clickSubject.next(event); });
      });
    }
    else
    {
      this.textLayers.forEach((layer) =>
      {
        layer.removeAllListeners('mousedown');
        layer.removeAllListeners('mouseup');
      });
    }
  }

  private async renderAllPages()
  {
    await this.delay(100);
    for (let i = 0; i < this.totalPagesCount; i++)
    {
      if (!this.ngxExtendedPdfViewerService.hasPageBeenRendered(i))
      {
        await this.ngxExtendedPdfViewerService.renderPage(i);
        // Small delay to prevent internal pdfJs bug
        await this.delay(100);
      }
    }
  }

  private async setHighlights(highlights: HighlightRect[], secondaryHighlights: HighlightRect[])
  {
    this.removeAllHighlights();
    this.highlights = highlights;
    this.secondaryHighlights = secondaryHighlights;

    const highlightPrimaryPromise = new Promise<void>((resolve) => {
      this.pageDivs.forEach((div, pageNumber) => {
        this.highlightRects(this.highlights, pageNumber, true);
      });
      resolve();
    });

    const highlightSecondaryPromise = new Promise<void>((resolve) => {
      this.pageDivs.forEach((div, pageNumber) => {
        this.highlightRects(this.secondaryHighlights, pageNumber, false);
      });
      resolve();
    });

    await Promise.all([highlightPrimaryPromise, highlightSecondaryPromise]);
    await this.subTaskAdobeMessagesToPdfDraw();
  }

  private focusHighlight(highlight: HighlightRect)
  {
    const x = parseInt(highlight.div.style.left, 10);
    const y = parseInt(highlight.div.style.top, 10);
    const adjustedWidth = parseInt(highlight.div.style.width, 10);
    const adjustedHeight = parseInt(highlight.div.style.height, 10);

    const pageDiv = this.pageDivs.get(highlight.pageNumber);
    const canvasWrapper = pageDiv.querySelector('.canvasWrapper') as HTMLDivElement;

    if (this.focusedHighlightElement)
    {
      this.focusedHighlightElement.remove();
    }

    const highlightDiv = document.createElement('div');
    highlightDiv.style.position = 'absolute';
    highlightDiv.style.left = `${x}px`;
    highlightDiv.style.top = `${y}px`;
    highlightDiv.style.width = `${adjustedWidth}px`;
    highlightDiv.style.height = `${adjustedHeight}px`;
    highlightDiv.style.backgroundColor = FOCUS_HIGHLIGHT_FILL_COLOR;
    highlightDiv.style.border = `2px solid ${FOCUS_HIGHLIGHT_BORDER_COLOR}`;
    highlightDiv.style.opacity = '0.7';

    canvasWrapper.appendChild(highlightDiv);
    this.focusedHighlightElement = highlightDiv;

    this.focusedHighlightElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  private removeAllHighlights()
  {
    this.highlights.forEach(h => h.div?.remove());
    this.secondaryHighlights.forEach(h => h.div?.remove());
    this.focusedHighlightElement?.remove();
    this.highlights = [];
    this.secondaryHighlights = [];
    this.clearParagraphHighlights();
  }

  private async highlightRects(highlights: HighlightRect[], pageNumber: number, active: boolean)
  {
    if (!highlights || highlights.length === 0)
    {
      return;
    }

    // Wait for the zoom change to finish before rendering the highlights
    if (this.zoomChangePromise)
    {
      await this.zoomChangePromise;
    }

    const pageDiv = this.pageDivs.get(pageNumber);
    const pageHighlights = highlights.filter(h => h.pageNumber === pageNumber);

    let mergedRectsWidth = 0;
    pageHighlights.forEach((highlight, index) =>
    {
      const rect = highlight.rect;
      const scale = this.getScale(highlight.zoomFactor);

      const adjustedLeft = rect.left * scale;
      const adjustedTop = rect.top * scale;
      const adjustedWidth = rect.width * scale;
      const adjustedHeight = rect.height * scale;

      const prev = index > 0 ? pageHighlights[index - 1] : null;
      if (prev && prev.color === highlight.color && prev.rect.top === rect.top)
      {
        mergedRectsWidth += adjustedWidth;
        prev.div.style.width = `${mergedRectsWidth}px`;
        highlight.div = prev.div;
      }
      else
      {
        const highlightDiv = document.createElement('div');
        highlightDiv.addEventListener('click', () => this.onHighlightClicked(highlight));
        highlightDiv.classList.add('highlight-rect');
        highlightDiv.style.cursor = 'pointer';
        highlightDiv.style.position = 'absolute';
        highlightDiv.style.left = `${adjustedLeft}px`;
        highlightDiv.style.top = `${adjustedTop}px`;
        highlightDiv.style.width = `${adjustedWidth}px`;
        highlightDiv.style.height = `${adjustedHeight}px`;
        highlightDiv.style.backgroundColor = highlight.color;
        highlightDiv.style.userSelect = 'text';
        highlightDiv.style.mixBlendMode = 'multiply';

        if (!active)
        {
          highlightDiv.style.opacity = '0.3';
          highlightDiv.style.zIndex = '0';
        }
        else
        {
          highlightDiv.style.zIndex = '1';
        }

        highlight.div = highlightDiv;
        mergedRectsWidth = adjustedWidth;

        pageDiv.appendChild(highlightDiv);
      }
    });
  }

  private onHighlightClicked(highlight: HighlightRect)
  {
    this.highlightClicked.emit(highlight);
  }

  private getScale(highlightZoomFactor: number): number
  {
    return (this.zoomFactor / highlightZoomFactor);
  }

  private delay(ms: number)
  {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private onScroll = (event: Event): void =>
  {
    const target = event.target as HTMLElement;
    this.scrollChange.emit({ scrollTop: target.scrollTop, scrollLeft: target.scrollLeft });
  };

  async search(text: string): Promise<boolean>
  {
    if (text.length > 4500)
    {
      // text longer than 4500 characters breaks the search
      return;
    }
    return new Promise<boolean>((resolve) =>
    {
      const subscription = this.searchResult$.subscribe((found) =>
      {
        resolve(found);
        subscription.unsubscribe();
      });

      this.ngxExtendedPdfViewerService.find(text,
      {
        highlightAll: false,
        matchCase: false,
        wholeWords: false,
      });
    });
  }

  updateFindState(event: FindState)
  {
    if (event === FindState.NOT_FOUND)
    {
      this.searchResultSubject.next(false);
    }
    else if (event === FindState.FOUND)
    {
      this.searchResultSubject.next(true);
    }
  }

  private async getAllText()
  {
    let allText = '';
    await this.renderAllPages();
    for (let i = 1; i <= this.totalPagesCount; i++)
    {
      const text = await this.ngxExtendedPdfViewerService.getPageAsText(i);
      allText += ` ${text}`;
    }
    return allText;
  }

  private highlightParagraphAndGetText(target: HTMLElement): string
  {
    this.clearParagraphHighlights();
    const paragraphSpans = this.getParagraphSpans(target);
    paragraphSpans.forEach((span) =>
    {
      if (span.innerText?.trim().length > 0)
      {
        span.classList.add('highlight');
      }
    });

    let text = paragraphSpans.map((span) => span.textContent).join(' ');
    text = text.replace(/\s+/g, ' ').trim();
    return text;
  }

  private clearParagraphHighlights()
  {
    const highlightedSpans = this.el.nativeElement.querySelectorAll('.highlight');
    highlightedSpans.forEach((span) => span.classList.remove('highlight'));
  }

  private getParagraphSpans(target: HTMLElement): HTMLElement[]
  {
    let spansToAnalyze: HTMLElement[] = this.getTextSpans();
    if (spansToAnalyze?.length === 0)
    {
      return [];
    }
    spansToAnalyze.splice(spansToAnalyze.indexOf(target), 1);

    const nearbySpans: Set<HTMLElement> = new Set([]);
    const queue: HTMLElement[] = [target];
    const threshold = this.getThreshold(spansToAnalyze);

    while (queue.length > 0)
    {
      const currentTarget = queue.shift();
      spansToAnalyze = spansToAnalyze.filter(span => span !== currentTarget);
      nearbySpans.add(currentTarget);
      const newNearbySpans = this.getNearbySpans(currentTarget, spansToAnalyze, threshold.thresholdTop, threshold.thresholdLeft);

      for (const span of newNearbySpans)
      {
        spansToAnalyze = spansToAnalyze.filter(s => span !== s);

        let isDuplicated: boolean = false;
        nearbySpans.forEach((nearbySpan) =>
        {
          const spanRect = span.getBoundingClientRect();
          const nearbySpanRect = nearbySpan.getBoundingClientRect();

          if (spanRect.top === nearbySpanRect.top && spanRect.left === nearbySpanRect.left)
          {
            isDuplicated = true;
            return;
          }
        });

        if (!isDuplicated)
        {
          nearbySpans.add(span);
          queue.push(span);
        }
      }
    }

    const orderedNearbySpans = Array.from(nearbySpans).sort((a, b) =>
    {
      const rectA = a.getBoundingClientRect();
      const rectB = b.getBoundingClientRect();

      const margin = 2; // Consider a small margin for top comparison

      if (Math.abs(rectA.top - rectB.top) <= margin)
      {
        return rectA.left - rectB.left;
      }
      return rectA.top - rectB.top;
    });

    return Array.from(orderedNearbySpans);
  }

  private getNearbySpans(target: HTMLElement, spans: HTMLElement[], maxDistanceY: number, maxDistanceX: number): HTMLElement[]
  {
    maxDistanceX = maxDistanceX > 0 ? maxDistanceX : maxDistanceY;
    if (!spans || spans.length === 0)
    {
      return [];
    }

    const targetRect = target.getBoundingClientRect();

    const targetText = target.textContent.trim();
    const targetTextEndWithStop = targetText.endsWith('.');

    const closestSpans = spans.filter((span) =>
    {
      const spanRect = span.getBoundingClientRect();
      const isCloseHorizontally =
        Math.abs(spanRect.left - targetRect.left) < maxDistanceX ||
        Math.abs(spanRect.left - targetRect.right) < maxDistanceX ||
        Math.abs(spanRect.right - targetRect.left) < maxDistanceX;
      const isCloseVertically = Math.abs(spanRect.top - targetRect.top) < maxDistanceY;

      const isSpanUnderCurrentSpan = spanRect.top > targetRect.top;
      const spanText = span.textContent.trim();
      const spanTextEndWithStop = spanText.endsWith('.');
      const isSpanOverCurrentSpan = spanRect.top < targetRect.top;

      const isFullStop = (targetTextEndWithStop && isSpanUnderCurrentSpan) || (spanTextEndWithStop && isSpanOverCurrentSpan);

      return !isFullStop && isCloseHorizontally && isCloseVertically;

    });
    return closestSpans;
  }

  /**
   * Analyzes the distance between spans and calculates the average for
   * those that are close (distance less than 100px and greater than 1px)
   */
  private getThreshold(spans: HTMLElement[]): { thresholdTop: number; thresholdLeft: number }
  {
    if (spans.length < 2)
    {
      return { thresholdTop: 0, thresholdLeft: 0 };
    }

    let totalTopDistance = 0;
    let totalLeftDistance = 0;
    let countT = 0;
    let countL = 0;

    for (let i = 0; i < spans.length - 1; i++)
    {
      const rect1 = spans[i].getBoundingClientRect();
      const rect2 = spans[i + 1].getBoundingClientRect();

      const topDistance = Math.abs(rect2.top - rect1.top);
      if (topDistance > 1 && topDistance < 100)
      {
        totalTopDistance += topDistance;
        countT++;
      }
      const leftDistance = Math.abs(rect2.left - rect1.left);
      // Texts inside the same paragraph should not be too far apart horizontally
      if (leftDistance > 1 && leftDistance < 40)
      {
        totalLeftDistance += leftDistance;
        countL++;
      }
    }

    const thresholdTop = totalTopDistance / countT;
    const thresholdLeft = totalLeftDistance / countL;

    return { thresholdTop, thresholdLeft };
  }

  private getTextSpans(highlightedSpans?: boolean): HTMLElement[]
  {
    let spans: HTMLElement[] = [];
    this.textLayers.forEach((layer) =>
    {
      if (!layer)
      {
        return;
      }
      spans = spans.concat(Array.from(layer.querySelectorAll('span')));
    });
    spans = spans.filter((span) => !span.classList.contains('markedContent'));

    // spans = this.removeDuplicateSpans(spans);

    if (highlightedSpans)
    {
      const spansWithHighlights = spans.filter((span) => span.classList.contains('highlight'));
      spans = spansWithHighlights;
    }

    return spans;
  }

  // removeDuplicateSpans(spans: HTMLElement[]): HTMLElement[]
  // {
  //   return spans.reduce((uniqueSpans: HTMLElement[], currentSpan) =>
  //   {
  //     const text = currentSpan.textContent?.trim() || '';
  //     const rect = currentSpan.getBoundingClientRect();
  //     const width = Math.round(rect.width);
  //     const height = Math.round(rect.height);
  //     const top = Math.round(rect.top);
  //     const left = Math.round(rect.left);

  //     const existingSpan = uniqueSpans.find(span =>
  //     {
  //       const existingRect = span.getBoundingClientRect();
  //       const existingWidth = Math.round(existingRect.width);
  //       const existingHeight = Math.round(existingRect.height);
  //       const existingTop = Math.round(existingRect.top);
  //       const existingLeft = Math.round(existingRect.left);

  //       return (
  //         span.textContent?.trim() === text &&
  //         Math.abs(existingWidth - width) <= 1 &&
  //         Math.abs(existingHeight - height) <= 1 &&
  //         Math.abs(existingTop - top) <= 1 &&
  //         Math.abs(existingLeft - left) <= 1
  //       );
  //     });

  //     if (existingSpan)
  //     {
  //       const existingRect = existingSpan.getBoundingClientRect();
  //       if ((width * height) > (existingRect.width * existingRect.height))
  //       {
  //         const index = uniqueSpans.indexOf(existingSpan);
  //         uniqueSpans[index] = currentSpan;
  //       }
  //     }
  //     else
  //     {
  //       uniqueSpans.push(currentSpan);
  //     }
  //     return uniqueSpans;
  //   }, []);
  // }


  /**
   *
   * OLD PDF VIEWER FUNCTIONS - TO BE REMOVED OR TRANSLATED TO THE NEW ONE (ngx-extended-pdf-viewer)
   *
   */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDrag(event: MouseEvent, pdfViewer: any)
  {
    if (!this.panOnClickDrag)
    {
      return;
    }
    if (this.isDragging)
    {
      const pdfContainer = pdfViewer.element.nativeElement.children[0] as HTMLElement;
      const x = pdfContainer.scrollLeft - event.movementX;
      const y = pdfContainer.scrollTop - event.movementY;
      pdfContainer.scrollTo(x, y);
    }
  }

  onDragStarted()
  {
    this.isDragging = true;
  }

  onDragEnded()
  {
    this.isDragging = false;
  }

  onMouseWheel(event: WheelEvent)
  {
    event.preventDefault();
    if (event.deltaY < 0)
    {
      this.zoomIn();
    }
    else
    {
      this.zoomOut();
    }
  }

  /**
   * https://github.com/VadimDez/ng2-pdf-viewer#after-load-complete
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onLoadComplete(event: any)
  {
    this.totalPages.emit(event._pdfInfo.numPages);
  }

  zoomIn()
  {
    if (this.zoom < 8)
    {
      this.zoom += 0.25;
    }
  }

  zoomOut()
  {
    if (this.zoom >= 0.50)
    {
      this.zoom -= 0.25;
    }
  }

  /**
   * https://github.com/VadimDez/ng2-pdf-viewer#page-rendered
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onPageRendered(event: any)
  {
    if (event.pageNumber === this.page)
    {
      this.busy = false;
      this.pageRendered.emit(event.source.canvas);
    }
  }


  async subTaskAdobeMessagesToPdfDraw()
  {
    this.processingAnnotation = true;
    await this.ngxExtendedPdfViewerService.removeEditorAnnotations();
    if(this._subTaskMessages?.length > 0)
    {
      for(const stMessage of this._subTaskMessages)
      {
        if (stMessage.adobeAnnotationData && !this.isDeleteComment(stMessage))
        {
          const metadata = JSON.parse(stMessage.adobeAnnotationData.metadataJson);
          const parsedAnnotation: PdfSerializedAnnotation = this.moonPdfViewerService.parseAdobeAnnotationToPdfAnnotation(
                                metadata.selector.subtype,
                                metadata.selector.boundingBox,
                                metadata.selector.subtype === 'shape' ? metadata.selector.inkList[0] : metadata.selector.quadPoints,
                                metadata.selector.strokeColor,
                                metadata.selector.strokeWidth,
                                stMessage.page);
          // Stringify to send as serialized annotation to pdf viewer
          await this.ngxExtendedPdfViewerService.addEditorAnnotation(JSON.stringify(parsedAnnotation));
        }
      }
    }
    this.processingAnnotation = false;
  }

  isDeleteComment(message: SubTaskMessage): boolean
  {
      // Timestamp default is older than timestamp of comment. If deletedTimestamp is greater than create timestamp , is a delete comment.
      if (!message.deletedTimestampUtc || (message.deletedTimestampUtc && (message.timestampUtc > message.deletedTimestampUtc)))
      {
        return false;
      }
      else
      {
        return true;
      }
  }

  private async focusAnnotation(annotationId: string): Promise<void>
  {
    const annotation = this._subTaskMessages.find((st) => st.adobeAnnotationData.adobeAnnotationId === annotationId
                                                     && !this.isDeleteComment(st));
    if (annotation)
    {
      this._subTaskMessages = this._subTaskMessages.filter((st) => st.adobeAnnotationData.adobeAnnotationId !== annotationId);
      this._subTaskMessages.push(annotation);
      await this.subTaskAdobeMessagesToPdfDraw();
    }
  }

  private async annotationCanceled(): Promise<void>
  {
    this.currentSerializedAnnotation = null;
    await this.subTaskAdobeMessagesToPdfDraw();
  }

  private async annotationSaved(): Promise<void>
  {
    this.currentSerializedAnnotation = null;
  }


}
